function About(){
  return (
    <div>
      <h1>About</h1>
      <p>Hi, I'm Tharun Shankar, known as 'comical' online. This website exists to show my portfolio. Not much here yet, though I hope to change that. Thanks for visiting this website. 

        One last thing before I go: set a reminder for March 22nd, 2024. Something cool is about to happen.
      </p>
    </div>
  )
}
 export default About;